<template>
  <div>
    <a-select
      v-model:value="listId"
      :filter-option="filterOption"
      :size="size"
      show-search
      aria-autocomplete="off"
      autocomplete="off"
      @change="handleChange"
    >
      <a-select-option v-for="data in inspections" :key="data.name" :value="data.id">
        {{ data.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  name: 'InputInsepctionList',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      listId: null,
      inspections: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.listId = nv;
      },
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$store.dispatch('inspection-list/list', { pageSize: 100 }).then((res) => {
        this.inspections = res.list;
      });
    },
    filterOption(input, option) {
      return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange() {
      this.$emit('change', this.listId);
      this.$emit('update:value', this.listId);
    },
  },
};
</script>

<style scoped></style>
