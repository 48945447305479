<template>
  <div class="column is-12 primary-item">
    <a-form-item :label="$t('Primary Item Name')" name="category" class="primary-item-name">
      <a-input v-model:value="item.category" :size="size" @change="handleChange" />
    </a-form-item>
    <div class="form-title is-flex">
      <div class="is-flex-grow-5">
        {{ $t('Secondary Item Name') }}
      </div>
      <div class="is-flex-grow-1">
        {{ $t('Mandatory') }}
      </div>
    </div>
    <SecondaryItem
      v-for="(vItem, i) in item.collection"
      :key="vItem.id || vItem._id"
      :value="item.collection[i]"
      :index="i"
      @delete="handleItemDelete"
      @change="handleItemChange"
    />
    <div class="add-button-wrap">
      <a-button type="primary" :size="size" class="button-secondary-outline" @click="handleAddItem">
        {{ $t('Add Secondary Item') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Primary Item Name": "Primary Item Name",
"Secondary Item Name": "Secondary Item Name",
"Mandatory": "Mandatory",
"Add Secondary Item": "Add Secondary Item"
},
"ja": {
"Primary Item Name": "大項目",
"Secondary Item Name": "中項目",
"Mandatory": "必須",
"Add Secondary Item": "中項目を追加"
}
}
</i18n>

<script>
import SecondaryItem from '@/views/inspections/components/SecondaryItem';

export default {
  name: 'InspectionPrimaryItem',
  components: { SecondaryItem },
  props: {
    value: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'large',
    },
  },
  emits: ['change', 'item-removed', 'item-added'],
  data() {
    return {
      item: {
        category: '',
        collection: [],
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nv) {
        this.item.category = nv.category;
        this.item.collection = JSON.parse(JSON.stringify(nv.collection));
      },
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', this.item);
    },
    handleItemChange({ item, index }) {
      this.item.collection[index] = item;
      this.handleChange();
    },
    handleAddItem() {
      const { collection } = this.item;
      const item = {
        _id: collection.length + 1,
        body: '',
        image: '',
        isMandatory: 0,
      };

      this.item.collection.push(item);
      this.$emit('item-added', item);
    },
    handleItemDelete(item, index) {
      this.item.collection.splice(index, 1);

      if (this.item.collection.length === 0) {
        this.handleAddItem();
      }

      this.handleChange();
      this.$emit('item-removed', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.primary-item {
  padding-bottom: 1.5rem !important;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #dcdcdc;
}

.primary-item-name {
  margin-bottom: 0 !important;
}

.add-button-wrap {
  padding-left: 2rem;
}

.form-title.has-right {
  display: flex;

  .right {
    text-align: right;
    margin-left: auto;
  }
}
</style>
