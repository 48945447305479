<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12 is-align-items-center is-flex is-justify-content-space-between">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ form.id ? $t('Edit Inspection List') : $t('Add Inspection List') }}
      </h3>
      <DelInspection v-if="form.id" :record="form" @delete="$emit('close')" />
    </div>
    <a-form-item v-show="!form.id" class="column is-12" :label="$t('Copy from')">
      <InputInspectionList
        v-model:value="copyOfId"
        style="min-width: 200px"
        @change="handleCopyList"
      />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Inspection List Name')" name="name">
      <a-input v-model:value="form.name" :size="size" />
    </a-form-item>
    <div class="sep" />
    <PrimaryItem
      v-for="(item, index) in form.items"
      :key="index"
      v-model:value="form.items[index]"
      :index="index"
      @change="
        (val) => {
          handlePrimaryItemChange(val, index);
        }
      "
      @item-removed="handleItemRemoved"
    />
    <div class="add-button-wrap column is-12">
      <a-button
        type="primary"
        :size="size"
        class="button-secondary-outline"
        @click="handleAddPrimaryItem"
      >
        {{ $t('Add Primary Item') }}
      </a-button>
      <a-button
        v-if="form.items.length > 1"
        style="margin-left: 1rem"
        type="danger"
        :size="size"
        @click="handleRemovePrimary"
      >
        {{ $t('Remove One Item') }}
      </a-button>
    </div>
    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        v-permission="['modify-inspection']"
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en": {
"Edit Inspection List": "Edit Inspection List",
"Add Inspection List": "Inspection List",
"Copy from": "Copy from",
"Inspection List Name": "Inspection List Name",
"Add Primary Item": "Add Primary Item",
"Remove One Item": "Remove One Item",
"Cancel": "Cancel",
"Save": "Save"
},
"ja": {
"Edit Inspection List": "確認箇所リストを編集",
"Add Inspection List": "確認箇所リスト",
"Copy from": "コピー",
"Inspection List Name": "確認箇所リスト名",
"Add Primary Item": "大項目を追加",
"Remove One Item": "アイテムを一つ削除する",
"Cancel": "取り消し",
"Save": "保存"
}
}
</i18n>

<script>
import { groupBy } from 'lodash';
import DelInspection from '@/views/inspections/components/DelInspection';
import PrimaryItem from '@/views/inspections/components/PrimaryItem';
import InputInspectionList from '@/views/inspections/components/InputInspectionList';

const defaultItem = {
  _id: 1,
  body: '',
  image: '',
  isMandatory: false,
};

export default {
  name: 'AddInspectionForm',
  components: {
    PrimaryItem,
    InputInspectionList,
    DelInspection,
  },
  props: {
    value: {
      type: Object,
      default() {
        return undefined;
      },
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      items: [],
      removedItems: [],
      copyOfId: undefined,
      form: {
        id: undefined,
        name: undefined,
        items: [
          {
            category: '',
            collection: [defaultItem],
          },
        ],
      },
      rules: {
        // todo
      },
      size: 'large',
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv) {
          this.form.id = nv.id;
          this.form.name = nv.name;
          if (nv.items.length) {
            this.items = JSON.parse(JSON.stringify(nv.items));
            this.form.items = JSON.parse(JSON.stringify(nv.items));
          }
        }
      },
    },
  },
  methods: {
    async handleCopyList(listId) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      const data = await this.$store.dispatch('inspection-list/get', listId);
      const grouped = groupBy(data.items, 'category');

      const items = Object.keys(grouped).map((category) => {
        const collection = grouped[category].map((item) => ({
          _id: item.id,
          body: item.body,
          isMandatory: item.isMandatory,
          image: '',
        }));

        return { collection, category };
      });

      this.$store.commit('HIDE_FULLSCREEN_LOADER');

      this.form = {
        name: data.name,
        items: JSON.parse(JSON.stringify(items)),
      };
      this.items = JSON.parse(JSON.stringify(items));
    },
    async handleSubmit() {
      await this.$refs.form.validate();
      this.loading = true;
      try {
        const { images, ...payload } = this.createPayload();

        if (payload.id) {
          await this.updateInspectionList(payload, images);
        } else {
          await this.createInspectionList(payload, images);
        }

        this.$refs.form.resetFields();
        this.$emit('close');
      } finally {
        this.loading = false;
      }
    },
    createPayload() {
      const items = [];
      const images = [];

      for (let i = 0, imageIndex = 0; i < this.items.length; i += 1) {
        const { category, collection } = this.items[i];

        for (let j = 0; j < collection.length; j += 1) {
          const { image, ...item } = collection[j];
          const imageAttr = { itemId: item.id, image };

          if (!item.id) {
            imageAttr.newIndex = imageIndex;
            imageIndex += 1;
          }

          images.push(imageAttr);
          items.push({ category, ...item });
        }
      }

      return {
        id: this.form.id,
        name: this.form.name,
        items,
        images,
      };
    },
    async createInspectionList(payload, images) {
      const { data } = await this.$store.dispatch('inspection-list/add', payload);
      const { id, items } = data;

      await this.uploadImages(id, images, items);
    },
    async updateInspectionList(payload, images) {
      const { data } = await this.$store.dispatch('inspection-list/update', payload);
      const { updated, created } = data;

      await this.uploadImages(updated.id, images, created);
      // deleted items
      const deletedIds = this.removedItems.map((removed) => removed.id);
      await this.$store.dispatch('inspection-list/deleteItems', {
        listId: updated.id,
        ids: deletedIds,
      });
    },
    uploadImages(listId, images, newItems) {
      const upload = [];

      for (let i = 0; i < images.length; i += 1) {
        const { itemId, image, newIndex } = images[i];

        if (image && typeof image === 'object') {
          const imagePayload = {
            listId,
            id: itemId,
            file: image,
          };

          // images for new items
          if (!itemId && newItems[newIndex] !== undefined) {
            imagePayload.id = newItems[newIndex].id;
          }

          upload.push(this.$store.dispatch('inspection-list/addItemImage', imagePayload));
        }
      }

      return Promise.all(upload);
    },
    handlePrimaryItemChange(val, index) {
      this.items[index] = val;
    },
    handleAddPrimaryItem() {
      const primary = {
        category: '',
        collection: [defaultItem],
      };

      this.form.items.push(primary);
    },
    handleRemovePrimary() {
      const item = this.form.items.pop();
      const collection = item ? item.collection : [];

      for (let i = 0; i < collection.length; i += 1) {
        const item = collection[i];

        if (item.id) {
          this.removedItems.push(item);
        }
      }
    },
    handleItemRemoved(item) {
      if (item.id) {
        this.removedItems.push(item);
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.sep {
  border-top: 1px solid #dcdcdc;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
</style>
