<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Inspection List') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchData" />
          </div>
          <div v-permission="['add-inspection']" class="column is-narrow">
            <ModalWide>
              <template #default="{ hide }">
                <AddInspectionForm
                  @close="
                    () => {
                      hide();
                      fetchData();
                    }
                  "
                  @cancel="hide"
                />
              </template>
              <template #handler="{ show }">
                <a class="ant-btn ant-btn-lg ant-btn-primary" @click="show">
                  {{ $t('New Inspection List') }}
                </a>
              </template>
            </ModalWide>
          </div>
        </div>
        <a-table
          row-key="id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #id="{ text }">
            {{ text }}
          </template>
          <template #name="{ record }">
            {{ record.name }}
          </template>

          <template #propertyRoom="{ record }">
            <ul
              v-if="record.rooms.length"
              class="property-room-list"
            >
              <li
                v-for="n in record.rooms"
                :key="n"
              >
                {{ n.property.abbreviation }}, {{ n.name }}
              </li>
            </ul>
            <Modal>
              <template #default="{ hide }">
                <AddRoomForm
                  :value="record"
                  @close="
                    () => {
                      hide();
                      fetchData();
                    }
                  "
                  @cancel="hide"
                />
              </template>
              <template #handler="{ show }">
                <a v-permission="['modify-inspection']" @click.stop.prevent="show">
                  {{ $t('Add Room') }}
                </a>
              </template>
            </Modal>
          </template>
        </a-table>
      </div>
    </div>
    <ModalWide ref="editInspectionModal">
      <template #default="{ hide }">
        <AddInspectionForm
          :value="selectInspection"
          @close="
            () => {
              hide();
              fetchData();
            }
          "
          @cancel="hide"
        />
      </template>
    </ModalWide>
  </div>
</template>

<i18n>
{
"en": {
"Inspection List": "Inspection List",
"New Inspection List": "New Inspection List",
"Inspection List Name": "Inspection List Name",
"Property, Room Name": "Property, Room Name",
"Add Room": "Add Room"
},
"ja": {
"Inspection List": "確認箇所リスト",
"New Inspection List": "新規確認箇所リスト",
"Inspection List Name": "確認箇所リスト名",
"Property, Room Name": "物件名, 部屋番号",
"Add Room": "部屋を追加"
}
}
</i18n>

<script>
import groupBy from 'lodash/groupBy';
import SearchForm from '@/views/inspections/components/SearchForm';
import Modal from '@/components/Modal';
import AddInspectionForm from '@/views/inspections/components/AddInspectionForm';
import AddRoomForm from '@/views/inspections/components/AddRoomForm';
import ModalWide from '@/components/ModalWide';
import list from '@/views/inspections/mixins/list';

export default {
  name: 'InspectionList',
  components: {
    ModalWide,
    AddRoomForm,
    AddInspectionForm,
    Modal,
    SearchForm,
  },
  mixins: [list],
  data() {
    return {
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Inspection List Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Property, Room Name'),
          dataIndex: 'propertyRoom',
          key: 'propertyRoom',
          slots: { customRender: 'propertyRoom' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
      selectInspection: undefined,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    rowClicked(record) {
      return {
        onClick: async () => {
          this.$store.commit('SHOW_FULLSCREEN_LOADER');

          const data = await this.$store.dispatch('inspection-list/get', record.id);
          const grouped = groupBy(data.items, 'category');

          const items = Object.keys(grouped).map((category) => {
            const collection = grouped[category].map((item) => {
              const { images, ...attr } = item;

              return {
                ...attr,
                image: images.length > 0 ? images[0].path : '',
              };
            });

            return { collection, category };
          });

          this.$store.commit('HIDE_FULLSCREEN_LOADER');

          this.selectInspection = {
            id: data.id,
            name: data.name,
            items,
          };
          this.$refs.editInspectionModal.show();
        },
      };
    },
  },
};
</script>
