<template>
  <Delete
    :action-handler="action"
    :payload-value="payloadValue"
    :title="$t('title')"
    :description="$t('desc', { name: record.name })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { name: record.name })"
    @close="$emit('delete')"
  />
</template>

<i18n>
{
"en": {
"title": "Delete Inspection list?",
"desc": "Are you sure you want to delete inspection list <strong>{name}</strong> ?",
"deleted-title": "Inspection list deleted",
"deleted-desc": "Inspection list <strong>{name}</strong> has been removed."
},
"ja": {
"title": "確認箇所リストを削除?",
"desc": "本当に <strong>{name}</strong> を削除しますか？",
"deleted-title": "確認箇所リスト削除完了",
"deleted-desc": "確認箇所リスト <strong>{name}</strong> が削除されました"
}
}
</i18n>

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DeleteInspection',
  components: {
    Delete,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
    action: {
      type: String,
      default: 'inspection-list/delete',
    },
    payload: {
      type: String || Object,
      default: null,
    },
  },
  emits: ['delete'],
  computed: {
    payloadValue() {
      if (this.payload) {
        return this.payload;
      }

      return { id: this.record.id };
    },
  },
};
</script>
